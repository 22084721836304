<template>
  <div class="homeSearch">
    <div class="logo">
      <img
        src="@/assets/all_Icon/drawable-xxhdpi/icon_login_logo.png"
        class="logo_img"
      />
    </div>
    <div class="messagelogin">
      <div class="upload_box" @click="gouploadApp()">下载App</div>
    </div>
    <div class="input">
      <van-field
        left-icon="search"
        @focus="changePage"
        v-model="value"
        placeholder="请输入内容"
      />
      <!-- <van-icon name="search" class="searchIcon" @click="changePage" /> -->
    </div>

    <div class="messageIcon borderK" v-if="isflag" @click="gopath('/login')">
      <!-- <img
                src="@/assets/all_Icon/drawable-xhdpi/icon_home_message.png"
                class="icon"
              />
              <div class="messNum">3</div> -->
      登录
    </div>
    <div class="messageIcon userImg" v-if="!isflag" @click="gopath('/my')">
      <img src="https://file.saen.com/default_head.jpg" class="icon" />
      <!-- <div class="messNum">3</div> -->
    </div>
    <!-- <div class="downApp" @click="downApp">下载App</div> -->
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      value: "",
      isflag: false,
    };
  },
  created() {
    this.isflag = localStorage.getItem("tokenSaen") ? false : true;
    console.log(localStorage.getItem("tokenSaen"));
  },
  methods: {
    gouploadApp() {
      window.location.href = "https://www.saen.com/app/";
    },
    changePage() {
      this.$emit("changepage");
      console.log(1111111111);
    },
    gopath(val) {
      this.$router.push({ path: val });
    },
    downApp() {
      window.open("https://www.saen.com/app/");
    },
  },
};
</script>

<style lang="less" scoped>
.homeSearch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 93%;
  background: #fff;
  z-index: 999;
  padding: 0.2rem 0;
  .logo {
    .logo_img {
      width: 100px;
      height: 36px;
      background: #f6f7f7;
      // border-radius: 16px;
    }
  }
  .input {
    margin: 0 5px;
    .searchIcon {
      font-size: 23px;
    }
    .van-cell {
      //   margin: 0 4px;
      height: 34px;
      line-height: 17px;
      background: #f6f7f7;
      border-radius: 14px; /*no*/
      font-size: 12px;
    }
  }
  .messagelogin {
    width: 40%;
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    .upload_box {
      width: 80%;
      height: 30px;
      background: #5d7dff;
      font-family: PingFangSC-Regular, PingFang SC;
      border-radius: 5px;
      font-size: 12px;
      text-align: center;
      line-height: 30px;
      color: #ffffff;
    }
  }
  .messageIcon {
    width: 10%;
    position: relative;
    text-align: center;
    .icon {
      width: 100%;
    }
    .messNum {
      position: absolute;
      right: -2px;
      top: -2px;
      color: #fff;
      background: #e15a5a;
      border-radius: 50%;
      padding: 2px;
      width: 12px;
      height: 12px;
      line-height: 12px;
      text-align: center;
      scale: 0.4;
    }
  }
  .downApp {
    width: 15%;
    // border: 1px solid #333333;
    // padding: 6px 10px;
    // border-radius: 500px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    text-align: center;
    white-space: nowrap;
  }
  .borderK {
    border: 1px solid #333;
    padding: 3px 5px;
    margin: 0 3px;
    border-radius: 500px;
    min-width: 33px;
  }
  .userImg {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    // margin-bottom: 4px;
  }
}
</style>    