<template>
    <div class="home">
        <search @changepage="changepage"></search>

        <!-- 轮播图 -->
        <div class="banner">
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                <van-swipe-item v-for="(item, index) in bannerList" :key="index">
                    <!-- <img class="banner_img" :src="item.imgPath" alt="banner" @click="gopage(item.linkUrl)"/> -->
                    <img class="banner_img" :src="item.imgPath" alt="banner" @click="bannerTo(item)" />
                </van-swipe-item>
            </van-swipe>
        </div>

        <!-- 12宫格 -->
        <div class="theme1">
            <van-swipe class="my-swipe" :loop="false" indicator-color="white">
                <van-swipe-item class="swiper_item" v-for="(i, ind) in theme5List.length" :key="ind">
                    <div class="item" v-for="(item, index) in theme5List[i - 1]" :key="index" @click="goHomeGrid(item)">
                        <img :src="item.icon" class="img" />
                        <div class="text">{{ item.name }}</div>
                    </div>
                </van-swipe-item>
            </van-swipe>
        </div>

        <!-- 4宫格 -->
        <div class="theme2">
            <div v-for="(item, index) in theme2List" :key="index" class="item" :class="item.backColor" @click="gopage(item.link)">
                <div class="left">
                    <div class="title">
                        {{ item.title }}
                    </div>
                    <div class="text">
                        {{ item.text }}
                    </div>
                </div>
                <div class="right">
                    <img :src="item.img" class="img" />
                </div>
            </div>
        </div>
        <div class="line_back"></div>

        <!-- 直播 -->
        <div class="theme3">
            <div class="top">
                <div class="title">直播</div>
                <div class="more" @click="gopage('/homeLiveList')">更多></div>
            </div>
            <div class="item" v-for="(item, index) in theme8List" :key="index" @click="onYuYue2(item)">
                <div class="left">
                    <div class="avatar">
                        <img :src="item.teacherHeaderPath" class="avatar_img" />
                        <div class="avatar_icon"></div>
                    </div>
                    <div class="avatar_text">{{ item.typeName }}</div>
                </div>
                <div class="right">
                    <div class="title">{{ item.name }}</div>
                    <div class="time">时间：{{ item.liveTime }}</div>
                    <div class="bottom">
                        <div>
                            <span class="name">{{ item.teacherName }}</span>
                            <span class="price">
                                {{ item.isFree == 1 ? item.price : "免费" }}
                            </span>
                        </div>
                        <!-- <div class="btn" @click="appointment(item)">{{ item.typeBtn }}</div> -->
                        <div v-if="item.type == 1" class="btn" @click="onYuYue(item)">进入</div>
                        <div v-if="item.type == 2" class="btn" @click="onYuYue(item)">预约</div>
                        <div v-if="item.type == 3" class="btn" @click="onYuYue(item)">购买</div>
                        <div v-if="item.type == 4" class="btn" @click="onYuYue(item)">待开播</div>
                        <div v-if="item.type == 5" class="btn" @click="onYuYue(item)">回放</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="line_back"></div>

        <!-- 热门课程tab -->
        <div class="theme4">
            <van-tabs v-model="theme4Active" @click="theme4Click">
                <van-tab v-for="(item, index) in theme4List" :key="index" :title="item.name">
                    <div class="list">
                        <div class="item" v-for="(course, key) in theme4ActiveList" :key="key" @click="$router.push('/ke/' + course.coursePackageId)">
                            <div class="left">
                                <img :src="course.coverPath" class="img" />
                                <div class="mark">
                                    {{course.isFree == 0? "免费" : course.isDiscount == 0? "热卖" : "打折"}}
                                </div>
                            </div>
                            <div class="right">
                                <div class="title">{{ course.courseName }}</div>
                                <div class="text">{{ course.courseSecondName }}</div>
                                <div v-if="course" class="mark">
                                    <!-- {{ course.isFree == 0 ? "免费" : "¥" + course.price }} -->
                                    <span v-if="course.price && course.discountPrice==null || course.discountPrice==''">¥{{course.price}}</span>
                                    <span v-else-if="course.discountPrice" style="text-decoration:line-through;">¥{{course.price}}</span>
                                    <span v-else>免费</span>
                                    <span v-if="course.discountPrice" style="margin-left:10px">¥{{course.discountPrice}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </van-tab>
            </van-tabs>
        </div>

        <!-- 名师答疑 -->
        <div class="theme5">
            <div class="top">
                <div class="title">名师答疑</div>
                <!-- <div class="more" @click="gopage()">更多></div> -->
            </div>
            <div class="list">
                <div class="item" v-for="(item, index) in theme9List" :key="index">
                    <div class="ask">
                        <div class="icon">问答</div>
                        <div class="title_text">{{ item.questionName }}</div>
                    </div>
                    <div class="answer">
                        <span class="answer_span">答：</span> {{ item.answerContent }}
                    </div>
                </div>
            </div>
        </div>
        <div class="noMore">—— 没有更多啦～——</div>

        <!-- 广告 -->
        <van-popup v-model="showAd" :close-on-click-overlay="false" style="background:transparent;">
            <div class="AdPopup">
                <div class="AdBody" v-for="(item,i) in AdData" :key="i">
                    <p class="AdTitle">{{item.title}}</p>
                    <van-image class="img" fit="contain" :src="item.imgPath" @click="toPage(item)" style="vertical-align: middle;" />
                </div>
                <div class="closeBox">
                    <div class="Ad_close" @click="onClose">
                        <van-icon size="22px" color="#ccc" name="cross"/>
                    </div>
                </div>
            </div>
        </van-popup>
        

        <!-- 底部导航 -->
        <tabBar></tabBar>
    </div>
</template>
<script>
import {
    getBanner,
    theme1,
    theme2,
    theme3,
    theme5,
    theme6,
    theme8,
    theme9,
    theme10,
    reserve,
    liveYuYue,
    popupWindowList
} from "@/api/home.js";
import search from "./component/search.vue";
import tabBar from "@/components/tabBar";
import { mapMutations } from 'vuex'
import {kbt} from '@/utils/utils.js'

export default {
    components: { search, tabBar },
    data() {
        return {
            bannerList: [],
            theme5List: [],
            theme8List: {}, // 讲师
            theme9List: [], // 问答
            theme2List: [
                {
                    title: "选课中心",
                    text: "看好课，享优惠",
                    backColor: "color1",
                    img: require("@/assets/all_Icon/drawable-xhdpi/icon_home_select.png"),
                    link: "/xuanke",
                },
                {
                    title: "直播",
                    text: "金牌讲师面对面",
                    backColor: "color2",
                    img: require("@/assets/all_Icon/drawable-xhdpi/icon_home_zhibo.png"),
                    link: "/homeLiveList",
                },
                {
                    title: "题库",
                    text: "考试通关大杀器",
                    backColor: "color3",
                    img: require("@/assets/all_Icon/drawable-xhdpi/icon_home_ti.png"),
                    link: "/tiku",
                },
                {
                    title: "证件照",
                    text: "考试报名照片制作",
                    backColor: "color4",
                    img: require("@/assets/all_Icon/drawable-xhdpi/icon_home_wen.png"),
                    link: "/photo",
                },
            ],
            theme4Active: 0,
            theme4List: [],
            theme4ActiveList: [],

            // 广告弹窗开关
            showAd:false,
            AdData:[], //广告数据

        };
    },
    name: "home",
    created() {
        this.getBanner();
        this.theme();

        this.popupWindowListFn() //广告
    },
    mounted() { },
    methods: {
        ...mapMutations(['setDirection']),
        changepage() {
            this.$router.push({ path: "/search" });
        },
        getBanner() {
            getBanner().then((res) => {
                for (let i = 0; i < 4; i++) {
                    res.data.data.push(res.data.data[0]);
                }
                this.bannerList = res.data.data;
            });
        },
        theme() {
            this.theme2();
            this.theme3();
            this.theme5();
            this.theme6();
            this.theme8();
            this.theme9();
            this.theme10();
        },
        theme1(id) {
            let param = new FormData();
            param.append("typeId", id);
            theme1(param).then((res) => {
                console.log(res.data, "theme4ActiveList");
                this.theme4ActiveList = res.data.data;
            });
        },
        // 跳homeGrid
        goHomeGrid(item) {
            console.log('首页宫格--------------', item)
            // 存储到vuex
            this.$store.commit('setDirection', { firstId: item.id, learningName: item.name })
            this.$router.push({ path: '/class/'+item.id })
        },
        theme2() {
            theme2().then((res) => {
                console.log(res.data, 2);
            });
        },
        theme3() {
            theme3().then((res) => {
                this.theme4List = res.data.data;
                this.theme1(res.data.data[0].id);
            });
        },
        theme4(id) {
            theme4().then((res) => { });
        },
        theme5() {
            theme5().then((res) => {
                console.log(res.data, 5555555555);
                this.theme5List = this.spArr(res.data.data, 10);
            });
        },
        spArr(arr, num) {
            //arr是你要分割的数组，num是以几个为一组
            let newArr = []; //首先创建一个新的空数组。用来存放分割好的数组
            for (let i = 0; i < arr.length;) {
                //注意：这里与for循环不太一样的是，没有i++
                newArr.push(arr.slice(i, (i += num)));
            }
            return newArr;
        },
        theme6() {
            theme6().then((res) => {
                console.log(res.data, 6);
            });
        },
        theme8() {
            theme8().then((res) => {
                res.data.data.map((item, index) => {
                    if (item.type == 1) {
                        item.typeBtn = "进入";
                    }
                    if (item.type == 2) {
                        item.typeBtn = "预约";
                    }
                    if (item.type == 3) {
                        item.typeBtn = "购买";
                    }
                    if (item.type == 4) {
                        item.typeBtn = "待开播";
                    }
                    if (item.type == 5) {
                        item.typeBtn = "回放";
                    }
                });
                this.theme8List = res.data.data;
            });
        },
        // 点击卡片整体进入直播
        onYuYue2(item){
            console.log('预约直播22222---------',item)
            window.sessionStorage.setItem('liveName',item.name)

            let coursePackageId = ''
            let courseId = ''
            if(item.coursePackageId){
                coursePackageId = '/'+ item.coursePackageId
            }else{
                coursePackageId = '/'+ null
            }
            if(item.courseId){
                courseId = '/'+ item.courseId
            }else{
                courseId = ''
            }

            let channelId = kbt.encrypt(item.channelId) //直播id加密
            let state = "1";
            
            this.$router.push('/zhibo/'+ item.id+ '/'+ channelId+ '/'+ coursePackageId+ '/'+ courseId+'/'+state+'/'+item.liveType)
        },
        // 点击预约按钮
        onYuYue(item) {
            console.log('预约直播111---------',item)
            if (item.type == 1) { //进入直播
                // this.$router.push({
                //     path: "/zhibo",
                //     query: {
                //         id: item.id, //直播id
                //         coursePackageId: item.coursePackageId,
                //         courseId: item.courseId,
                //         channelId: item.channelId,
                //         liveTime: item.liveTime
                //     }
                // })

                let coursePackageId = ''
                let courseId = ''
                if(item.coursePackageId){
                    coursePackageId = '/'+ item.coursePackageId
                }else{
                    coursePackageId = '/'+ null
                }
                if(item.courseId ){
                    courseId = '/'+ item.courseId
                }else{
                    courseId = ''
                }
                let channelId = kbt.encrypt(item.channelId) //直播id加密
                let state = "1";
                this.$router.push('/zhibo/'+ item.id+ '/'+ channelId+ '/'+ coursePackageId+ '/'+ courseId+'/'+state+'/'+item.liveType)
            }
            if (item.type == 2) { //预约直播
                let data = new FormData()
                data.append('liveId', item.id)
                liveYuYue(data).then(res => {
                    if (res.data.code == 0) {
                        this.$toast('预约直播成功')
                        this.theme8();
                    } else {
                        this.$toast('预约失败，请稍后重试')
                    }
                })
            }
            if (item.type == 3) { //购买
                this.$router.push({
                    path: '/orderdetail',
                    query: {
                        liveId: item.id,
                        price: item.price
                    }
                })
            }
            if (item.type == 4) { //待开播
                let coursePackageId = ''
                let courseId = ''
                if(item.coursePackageId){
                    coursePackageId = '/'+ item.coursePackageId
                }else{
                    coursePackageId = '/'+ null
                }
                if(item.courseId){
                    courseId = '/'+ item.courseId
                }else{
                    courseId = ''
                }
                let state = "1";
                this.$router.push('/zhibo/'+ item.id+ '/'+ channelId+ '/'+ coursePackageId+ '/'+ courseId+'/'+state+'/'+item.liveType)
            }
            if (item.type == 5) { //回放
                
                
                let coursePackageId = ''
                let courseId = ''
                let state = '' // state=5回放
                if(item.coursePackageId){
                    coursePackageId = '/'+ item.coursePackageId
                }else{
                    coursePackageId = '/'+ null
                }
                if(item.courseId){
                    courseId = '/'+ item.courseId
                }else{
                    courseId = ''
                }
                if(item.type == 5){
                    state = '2'
                }else{state = '1'}
                let channelId = kbt.encrypt(item.channelId) //直播id加密
                this.$router.push('/zhibo/'+ item.id+ '/'+ channelId+ '/'+ coursePackageId+ '/'+ courseId+'/'+state+'/'+item.liveType)
            }

        },

        theme9() {
            theme9().then((res) => {
                this.theme9List = res.data.data;
            });
        },
        theme10() {
            theme10().then((res) => {
                console.log(res.data, 10);
            });
        },
        gopage(url) {
            this.$router.push({ path: url });
        },
        bannerTo(item) {
            if (item.type == 2) return
            window.location.href = item.linkUrl
        },
        theme4Click(name, title) {
            this.theme1(this.theme4List[name].id);
            console.log(name, title);
        },

        // 获取广告
        async popupWindowListFn(){
            let res = await popupWindowList()
            console.log('获取广告----------',res)
            if(res.data.code == 0) this.AdData = res.data.data
            this.AdData.forEach((item,i) => {
                if( !sessionStorage.getItem('isShowAd') && item.isShow== 1 ){ this.showAd = true }
            });
        },
        onClose(){
            this.showAd = false
            window.sessionStorage.setItem('isShowAd',2)
        },
        toPage(item){
            window.location.href= item.linkUrl
        },
    },
};
</script>

<style scoped lang="less">
.home {
    padding: 56px 14px 30px;
    background: #fff;
    .banner {
        .banner_img {
            width: 100%;
            border-radius: 5px; /*no*/
        }
    }
    .theme1 {
        margin-top: 16px;
        .swiper_item {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
        .item {
            width: 18%;
            margin: 2% 1%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            .img {
                width: 50%;
                margin: 8px auto 8px;
            }
            .text {
                width: 100%;
                font-size: 12px; /*no*/
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 18px;
                text-align: center;
                white-space: nowrap;
            }
        }
    }
    .theme2 {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 13px;
        margin-bottom: 19px;
        .item {
            width: 155px;
            padding: 14px 0 24px 15px;
            border-radius: 5px; /*no*/
            margin-bottom: 6px;
            position: relative;
            &:nth-of-type(odd) {
                margin-right: 2%;
            }
            .title {
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                line-height: 20px;
                font-weight: 550;
                margin-bottom: 4px;
            }
            .text {
                font-weight: 400;
            }
            .left {
                position: relative;
                z-index: 9;
            }
            .right {
                .img {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    z-index: 5;
                }
            }
        }
        .color1 {
            // background: #e9f8f5;
            color: #24a179;
            .text {
                color: #75bca1;
            }
        }
        .color2 {
            color: #d1323b;
            // background: #faeae6;
            .text {
                color: #dd757b;
            }
        }
        .color3 {
            color: #c48036;
            // background: #fff6e4;
            .text {
                color: #c69e70;
            }
        }
        .color4 {
            color: #4f34af;
            // background: #e7e5fc;
            .text {
                color: #a49dd1;
            }
        }
    }
    .theme3 {
        .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 6px;
            margin-top: 18px;
            .title {
                font-size: 17px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 550;
                color: #141414;
                line-height: 24px;
            }
            .more {
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 18px;
            }
        }
        .item {
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #dddddd;
            padding: 18px 0;
            &:last-child {
                border: none;
            }
            .left {
                width: 72px;
                position: relative;
                display: flex;
                flex-wrap: wrap;
                // margin-left: 10px;
                .avatar {
                    border-radius: 50%; /*no*/
                    border: 3px solid #fff;
                    background: #fff;
                    width: 72px;
                    height: 72px;
                    display: flex;
                    justify-content: center;
                    position: relative;
                    background: url("../../assets/all_Icon/drawable-xhdpi/icon_home_live.png")
                        no-repeat;
                    background-size: 100% 100%;
                    .avatar_img {
                        width: 75%;
                        position: absolute;
                        bottom: 6px;
                        object-fit: cover;
                    }
                }
                .avatar_text {
                    position: absolute;
                    bottom: 0;
                    z-index: 9;
                    background: #f8ab50;
                    padding: 1px 8px;
                    text-align: center;
                    width: 85%;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: #fff;
                    font-size: 12px;
                    border-radius: 500px; /*no*/
                }
            }
            .right {
                flex: 1;
                padding: 0 0 0 21px;
                .title {
                    font-size: 15px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 550;
                    color: #333333;
                    line-height: 22px;
                    margin-bottom: 6px;
                }
                .time {
                    font-size: 13px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 18px;
                }
                .bottom {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .name {
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #555555;
                        line-height: 19px;
                    }
                    .price {
                        font-size: 13px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #e53d3d;
                        line-height: 18px;
                    }
                    .btn {
                        background: #5d7dff;
                        border-radius: 500px; /*no*/
                        width: 67px;
                        height: 28px;
                        line-height: 28px;
                        text-align: center;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #ffffff;
                    }
                }
            }
        }
    }
    .theme4 {
        padding-top: 10px;
        /deep/ .van-tab {
            flex: none;
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            margin-right: 9px;
        }
        /deep/ .van-tab--active {
            font-weight: 600;
            font-size: 16px;
            color: #141414;
        }
        /deep/ .van-tabs__line {
            background: #5d7dff;
            height: 4px;
        }
        .list {
            .item {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding: 14px 0;
                border-bottom: 1px solid #dddddd;

                &:last-child {
                    border: none;
                }
                .left {
                    width: 109px;
                    position: relative;
                    margin-right: 9px;
                    border-radius: 5px; /*no*/
                    .img {
                        width: 100%;
                        border-radius: 5px; /*no*/
                    }
                    .mark {
                        opacity: 0.6;
                        position: absolute;
                        left: 0;
                        top: 0;
                        background: #000000;
                        border-radius: 5px 0px 5px 0px; /*no*/
                        z-index: 9;
                        font-size: 11px; /*no*/
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #ffffff;
                        padding: 2px 4px;
                    }
                }
                .right {
                    width: calc(100% - 118px);
                    .title {
                        font-size: 15px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 550;
                        color: #333333;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        word-wrap: break-word;
                    }
                    .text {
                        font-size: 13px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        word-wrap: break-word;
                        margin: 6px 0 8px;
                    }
                    .mark {
                        font-size: 13px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 550;
                        color: #e13838;
                        line-height: 18px;
                    }
                }
            }
        }
    }
    .theme5 {
        .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 21px;
            margin-top: 18px;
            .title {
                font-size: 17px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 550;
                color: #141414;
                line-height: 24px;
            }
            .more {
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 18px;
            }
        }
        .list {
            .item {
                .ask {
                    display: flex;
                    align-items: center;
                    margin-bottom: 12px;
                    .icon {
                        background: #eaeeff;
                        border-radius: 10px 0px 10px 0px; /*no*/
                        padding: 2px 10px;
                        margin-right: 6px;
                        color: #5f7dff;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        line-height: 17px;
                    }
                    .title_text {
                        font-size: 15px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 550;
                        color: #333333;
                        line-height: 22px;
                    }
                }
                .answer {
                    font-size: 13px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 18px;
                    padding: 13px 17px 12px 10px;
                    background: #f8f8f8;
                    border-radius: 5px; /*no*/
                    .answer_span {
                        font-size: 14px;
                        color: #333333;
                    }
                }
            }
        }
    }
    .noMore {
        margin: 14px 0;
        width: 100%;
        height: 100px;
        text-align: center;
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 15px;
    }
    .line_back {
        height: 7px;
        width: calc(100% + 28px);
        background: #f8f8f8;
        position: relative;
        left: -14px;
    }
}
.van-swipe {
    padding-bottom: 5px;
}
.van-swipe__indicators {
    bottom: 5px;
}
/deep/ .van-swipe__indicator {
    background: #eff1f7;
}
/deep/ .van-swipe__indicator--active {
    background: #c8d3ff !important;
    width: 13px;
    height: 3px;
    border-radius: 2px;
}


.AdPopup{
    // max-width: 80vw;

    .AdBody{
        height: auto;
        position: relative;
        .AdTitle{
            font-size: 14px;
            text-align: center;
            color: #fff;
            margin-bottom: 10px;
        }
        .img{
            left: 50%;
            transform: translate(-50%);
            max-width: 90vw;
            min-width: 50vw;
        }
    }
    
    .closeBox{
        margin-top: 60px;
        position: relative;
        .Ad_close{
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%);
            width: 22px;
            height: 22px;
            border: 1px solid #ccc;
            border-radius: 50%;
            z-index: 99999;
        }
    }
    

}
</style>
